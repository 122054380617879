import React from "react";
import { Link } from "react-router-dom";
import { FaUserAlt, FaFileAlt, FaChartLine, FaThLarge, FaStopwatch, FaSmile, FaUserFriends, FaThumbsUp, FaExchangeAlt } from "react-icons/fa";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";


const Navbar = () => {
    const navigate=useNavigate();
  return (
    <div className="navbar-container">
      <nav className="navbar">
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link to="/" className="nav-link">
              <FaUserAlt className="nav-icon" />
              My account
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/upload" className="nav-link">
              <FaFileAlt className="nav-icon" />
              My uploaded manuscript
            </Link>
          </li>
          <li className="maing">
            <Link to="/graphs" className="nav-link">
              <FaChartLine className="nav-icon" />
              Plot structure
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/plot-beats" className="nav-link">
              <FaThLarge className="nav-icon" />
              Plot beats
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/pacing" className="nav-link">
              <FaStopwatch className="nav-icon" />
              Pacing
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/emotions" className="nav-link">
              <FaSmile className="nav-icon" />
              Emotions
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/characters" className="nav-link">
              <FaUserFriends className="nav-icon" />
              Characters
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/recommendations" className="nav-link">
              <FaThumbsUp className="nav-icon" />
              Recommendations
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/compare" className="nav-link">
              <FaExchangeAlt className="nav-icon" />
              Compare
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
