import React, { useState, useEffect } from 'react';

import ReactApexChart from 'react-apexcharts';


class ApexChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      series: [{
        name: "Your Book",
        data: this.props.yourBookAnalysisData
        },
        {
          name: "best sellers - Thriller",
            data: this.props.bestSellersData
        }
      ],
      options: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: [2, 2],
          curve: 'straight',
          dashArray: [0, 0]
        },
        title: {
          text: 'Plot Structure',
          align: 'center',
          margin: 40, // add margin to the title

          style: {
            fontSize: '24px', // add this to increase the font size
            fontWeight: 'bold',
            marginBottom: '80px' // add margin bottom to the title
          },
        },
        legend: {
          tooltipHoverFormatter: function(val, opts) {
            return val + '  ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
          }
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6
          }
        },
        xaxis: {
          type: 'numeric',
          min: 0,
          max: 70,
          tickAmount: 7,
          tickInterval: 10,
          labels: {
            formatter: function(val) {
              return val.toString();
            
            },
            show: false // hide the x-axis labels
          }
        },
        yaxis: {
          type: 'numeric',
          min: -0.06,
          max: -0.015 ,
          tickAmount: 10,
          tickInterval: 0.004,
          labels: {
            formatter: function(val) {
              return val.toFixed(2);
            },
            show: false
          },
          title: {
            text: `Conflicts  \xa0\xa0\xa0  Resolutions`,
            style: {
              fontSize: "16px",
              fontWeight: "thin",
              fontFamily: "Arial",
            },
          }
          },
        tooltip: {
          y: [
            {
              title: {
                formatter: function (val) {
                  return val
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val 
                }
              }
            },
            {
              title: {
                formatter: function (val) {
                  return val;
                }
              }
            }
          ]
        },
        grid: {
          borderColor: '#f1f1f1',
        }
      },


    };
  }
  
  render() {
    
    return (
      <><div>  
        <h1>The girl with the dragon tattoo</h1>
      </div>
      <div style={{ height: "calc(100vh - 150px)", margin: "50px auto" }} id="chart">
        <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350} />
      </div></>
    );
  }
}

export default ApexChart;
