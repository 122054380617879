


import React, { useRef } from 'react';
import './GraphPage.css';

const GraphPage = (props) => {
    const { textFile } = props;

    // Ref to the container element of the GraphPage component
    const containerRef = useRef(null);

    // Function to handle scrolling to a chapter
    const scrollToChapter = (chapterIndex) => {
        const chapterElement = containerRef.current.querySelector(`#chapter-${chapterIndex}`);
        if (chapterElement) {
            // Scroll to the top of the chapter element
            chapterElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div className="text-container" ref={containerRef}>
            <div className='Manuscript-title'>Manuscript</div>
            {textFile.map((chapter, index) => (
                <div key={index} id={`chapter-${index+1}`}>
                    {/* <h2>{`Chapter ${index+1}`}</h2> */}
                    <p style={{textAlign: 'left'}}>{chapter}</p>
                </div>
            ))}
        </div>
    );
};

export default GraphPage;
  
