


import React from 'react';
import './Contens.css';

const Contents = (props) => {
    const { contents } = props;

    // Function to handle clicking on a chapter link
    const handleChapterClick = (event, chapterIndex) => {
        event.preventDefault();
        const chapterId = `#chapter-${chapterIndex}`;
        const chapterElement = document.querySelector(chapterId);
        if (chapterElement) {
            // Scroll to the chapter element
            chapterElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div className="text-container1">
            <div className='Manuscript-title1' style={{ marginBottom: '10px'}}> :Contents</div>
            {contents.map((content, index) => {
                const contentTitle = content;
                const contentLink = `#chapter-${index+1}`;
                return (
                    <div key={index} className="content-link">
                        <a href={contentLink} onClick={(event) => handleChapterClick(event, index+1)}>
                            {contentTitle}
                        </a>
                    </div>
                );
            })}
        </div>
    );
};

export default Contents;
