import React, { useState, useEffect } from 'react';
import './Louder.css';
import { useNavigate } from "react-router-dom";

function Louder() {
  const navigate=useNavigate();
  
  useEffect(() => {
    const waitTime = 5 * 1000; // convert 60 seconds to milliseconds
    const timeoutId = setTimeout(() => {
      // your code to be executed after 1 minute
      navigate("/graphs");
    }, waitTime);

    return () => {
      clearTimeout(timeoutId); // cancel the timeout if component unmounts before 1 minute
    };
  }, []); // empty dependency array means this effect runs only once during runtime
  return (
    <div className="Louder">
      <div className="Louder-icon">
        <div className="spinner"></div>
      </div>
      <div className="Louder-text">
        Processing your book...
      </div>
    </div>
  );
}

export default Louder;
