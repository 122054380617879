import React from "react";
import './App.css';
import ApexChart from './components/ApexChart';
import AuthPage from './components/AuthPage';
import Louder from './components/Louder';
import GraphPage from './components/GraphPage';
import UploadPage from './components/UploadPage';
import ShowGraph from './components/ShowGraph';
import Main from "./components/main"
import Contens from "./components/Contens"
import Navbar from  "./components/Navbar"
import { BrowserRouter,Route,Router,Routes, useNavigate } from "react-router-dom";

  
function App() {

  return (
    <div className="App">
      <Routes>
      <Route element={<Navbar /> } path="/Navbar"/>
        <Route element={<UploadPage/>} path="/upload"/>
        <Route element={<Main/>} path="/graphs"/>
        <Route element={<AuthPage /> } path="/"/>
        <Route element={<Louder /> } path="/load"/>
      
        </Routes>
    </div>
  );
}

export default App;


