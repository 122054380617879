import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import './AuthPage.css';

function AuthPage() {
  // const [email, setEmail] = useState('');
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [text, setText] = useState('');
  const [animationCompleted, setAnimationCompleted] = useState(false);

  useEffect(() => {
    // Check if user is already logged in
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
    }

    // Animate the text
    const interval = setInterval(() => {
      const storywiseText = 'Storywise.ai';
      setText((prevText) => {
        if (prevText === storywiseText) {
          setAnimationCompleted(true);
          return prevText;
        } else {
          return storywiseText.slice(0, prevText.length + 1);
        }
      });
    }, 300);
    return () => clearInterval(interval);
  }, []);

  const handleLogin = (e) => {
    e.preventDefault();
    if (username === "storywise_admin" && password === "storywise@@2023") {
      setIsLoggedIn(true);
      navigate("/upload");
    }
    else{

    }
  };

  const handleLogout = () => {
    // Remove token from local storage and set isLoggedIn to false
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };
  
  const navigate=useNavigate();
  
  useEffect(() => {
    // Set body styles to cover entire page
    document.body.style.backgroundImage = "url(/17973908.jpg)";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundPosition = "center";
    document.body.style.height = "100vh";
    document.body.style.margin = "0";
    document.body.style.padding = "0";
    return () => {
      document.body.style.backgroundImage = "";
      document.body.style.backgroundSize = "";
      document.body.style.backgroundPosition = "";
      document.body.style.height = "";
      document.body.style.margin = "";
      document.body.style.padding = "";
    };
  }, []);
  
  if (isLoggedIn) {
    return (
      <div className="auth-container">
        <p>You are logged in!</p>
        <button onClick={handleLogout}>Logout</button>
      </div>
    );
  } else {
    return (
      <form className="auth-container" onSubmit={handleLogin}>
        <h1>Welcome To</h1>
        {animationCompleted ? (
          <h2>Storywise.ai</h2>
        ) : (
          <h2>{text}</h2>
        )}
        <h3>The AI Wizard That Turns Your Story Into A Bestseller</h3>
        <h4>Log in to your account</h4>
        <input type="text" placeholder="User name" value={username} onChange={(e) => setUsername(e.target.value)} />
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <button type="submit">Login</button>
      </form>
    );
  }
}

export default AuthPage;
