import React, { useState, useEffect } from 'react';
import './UploadPage.css';
import { useNavigate } from "react-router-dom";



function UploadPage() {
    const navigate=useNavigate();
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileInput = (event) => {
        setSelectedFile(event.target.files[0]);
    }

    const handleUploadFile = (event) => {
        
        event.preventDefault();
        //Send the file to the server
        if (selectedFile) { // Check if a file has been selected
            const formData = new FormData();
            formData.append('file', selectedFile);
            navigate("/load");
        } else {
            alert("Please select a file to upload.");
        }

        // fetch('/upload', {
        //     method: 'POST',
        //     body: formData
        // })
        //     .then(response => {
        //         // Handle the response from the server
        //     })
        //     .catch(error => console.error(error));
    }
    useEffect(() => {
        // Set body styles to cover entire page
        document.body.style.backgroundImage = "url(/5153829.jpg)";
        document.body.style.backgroundSize = "cover";
        document.body.style.backgroundPosition = "center";
        document.body.style.height = "100vh";
        document.body.style.margin = "0";
        document.body.style.padding = "0";
        return () => {
          document.body.style.backgroundImage = "";
          document.body.style.backgroundSize = "";
          document.body.style.backgroundPosition = "";
          document.body.style.height = "";
          document.body.style.margin = "";
          document.body.style.padding = "";
        };
      }, []);

    return (
        <div className="container">
            <h1>Upload Manuscript</h1>
            <form onSubmit={handleUploadFile}>
                <div className="file-input">
                    <label htmlFor="file">Files should be PDF, EPUB, Mobi or TXT</label>
                    <input type="file" id="file" onChange={handleFileInput} />
                </div>
                <button type="submit">Upload</button>
            </form>
        </div>
    );
}

export default UploadPage;